import BaseButton from "component/button/BaseButton"
import { useDownloadContentExcel } from "hooks/useDownloadContentExcel"

/**
 * Table Header Excel
 * 
 * @param {{
 *  group: {
 *    value: string
 *    label: string
 *  }
 *  content: {
 *    id: string
 *    title: string
 *  }
 *  kelasId: string
 *  isDisabledButtonExcel?: boolean
 * }} props
*/
export default function TableHeaderExcel(props) {
  const { data, handler } = useDownloadContentExcel()

  return (
    <div>
      <BaseButton props={{ className: 'mb-3' }} onClick={() => {
        handler.downloadExcelHandler({
          kelasId: props.kelasId,
          group: props.group,
          content: props.content,
        })
      }} isLoading={data.isLoadingDownloadExcel} isDisabled={props.isDisabledButtonExcel | false}>
        <i className="fas fa-file-excel mr-1" />
        Excel
      </BaseButton>
    </div>
  )
}