import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { readonlyEditorConfig } from 'util/ckeditor-config';

export default function CkeditorReadonlyV2({ text }) {
  return <div className='containerCkeditorReadonly2'>
    <CKEditor
    editor={Editor}
    data={text}
    config={readonlyEditorConfig}
    isDisabled={true}
    disabled={true}
    isReadOnly={true}
    readOnly={true}
  />
    </div>
}
