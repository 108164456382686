import React, { useEffect, useState } from 'react'
import Select from 'react-select'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import DataTable from 'component/data-table'
import RaporBlockAllModal from './partials/rapor-block-all-modal'
import Item from './partials/datatable/item'
import useDebounce from 'util/use-debounce'
import DataTableWithFilter from 'component/data-table/indexWithFilter'
import TableHeaderZip from 'component/table/TableHeaderZip'

export default function InputRaporBlockAll() {
  /* ========================================= Helpers ======================================== */
  const { getRequest } = useBaseContext()

  /* ========================================= States ========================================= */
  const [loadingScores, setLoadingScores] = useState(false)
  const [historyRapor, setHistoryRapor]   = useState(null)

  /* ==================================== Datatable States ==================================== */
  const [limit, setLimit]               = useState(10)
  const [offset, setOffset]             = useState(0)
  const [search, setSearch]             = useState('')
  const [data, setData]                 = useState([])
  const [totalRecords, setTotalRecords] = useState(0)

  /* ========================================= Debounce ========================================= */
  const debouncedSearch = useDebounce(search, 750)

  /* ================================= Select Semester States ================================= */
  const [optionSemester, setOptionSemester] = useState([])
  const [selectSemester, setSelectSemester] = useState(null)

  /* =================================== Select Block States ================================== */
  const [optionBlock, setOptionBlock]     = useState([])
  const [selectedBlock, setSelectedBlock] = useState(null)

  /* =================================== Select Group States ================================== */
  const [groups, setGroups]           = useState([])
  const [selectGroup, setSelectGroup] = useState(null)

  /* ======================================== Functions ======================================= */
  const stringify = (queries) => '?' + Object.entries(queries).map(([key, value]) => `${key}=${value}`).join('&')

  async function getUserGroupRequired() {
    if (!selectedBlock?.value) return
    if (selectGroup && selectSemester) {
      const queries = {
        limit,
        offset,
        search,
        ta_semester_code : selectSemester?.value,
        period_id        : selectedBlock?.value,
        group_id         : selectGroup?.value,
      }
      const response = await getRequest(`get-list-siswa${stringify(queries)}`)
      if (response) {
        setData(response.data)
        setTotalRecords(response.totalRecords)
      }
    } else {
      setData([])
      setTotalRecords(0)
      setLimit(10)
      setOffset(0)
      setSearch('')
    }
  }

  async function getSemesterCode() {
    setLoadingScores(true)
    try {
      let response = await getRequest(`semester`)
      if (response) {
        let arr = []
        response.data.forEach((val) => {
          const arrItemToPush = {
            value: val.code,
            label: `${val.tahun_mulai}/${val.tahun_selesai} - ${
              val.title_render
            } ${val.status ? '(Aktif)' : ''}`,
          }
          
          if (val.status) {
            setSelectSemester(arrItemToPush)
          }

          arr.push(arrItemToPush)
        })

        setOptionSemester(arr)
      }
    } catch (error) {
      console.error('ERROR', { error })
    }
    setLoadingScores(false)
  }

  const getGroups = async () => {
    if (!selectSemester?.value || !selectedBlock) return
    setLoadingScores(true)
    const response = await getRequest(
      `semester/${selectSemester?.value}/groups-wali?period_id=${selectedBlock?.value}`,
    )
    if (response) {
      if (response.data.length) {
        const mapFunc = (item) => ({
          value: item.id,
          label: item.group,
          is_wali: !!item.groups_walis?.length
        })
        const _optionGroup = response.data.map(mapFunc)
        setGroups(_optionGroup)
        _optionGroup.length && setSelectGroup(_optionGroup[0])
      } else {
        setGroups([])
        setSelectGroup(null)
      }
    }
    setLoadingScores(false)
  }

  const getBlocks = async () => {
    if (selectSemester) {
      const response = await getRequest(`reportPeriod?semester_code=` + selectSemester?.value)
      if (response) {
        const mapFunc = item => ({
          label: item.name_period,
          value: item.id,
        })

        const _optionBlock = response.data?.filter(d => d.status)?.map(mapFunc)
        setOptionBlock(_optionBlock)
        _optionBlock.length && setSelectedBlock(_optionBlock[0])
      } else {
        setOptionBlock([])
        setSelectedBlock(null)
      }
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getSemesterCode()
  }, [])

  useEffect(() => {
    if (selectSemester == null) {
      setSelectGroup(null)
    }
    getUserGroupRequired()
  }, [selectGroup, limit, offset, debouncedSearch, selectSemester])

  useEffect(() => {
    getBlocks()
  }, [selectSemester])

  useEffect(() => {
    getGroups()
  }, [selectedBlock])

  useEffect(() => {
    setLimit(10)
    setOffset(0)
  }, [selectGroup])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Rapor Block All" />
        
        <div className="card user-profile-list">
          <div className="card-body">
            <div className="row mb-3">
              <div className="col-md-4 mb-3 mb-md-0">
                <span className="f-w-700 f-14">Semester</span>
                <Select
                  className   = "mt-2"
                  placeholder = "Pilih semester..."
                  isMulti     = {false}
                  options     = {optionSemester}
                  value       = {selectSemester}
                  isClearable = {true}
                  isLoading   = {loadingScores}
                  isDisabled  = {loadingScores}
                  onChange    = {(option) => {
                    setSelectSemester(option)
                    if (selectSemester) {
                      setSelectGroup(null)
                      setSelectedBlock(null)
                    }
                  }}
                />
              </div>
              {selectSemester && (
                <div className="col-md-4 mb-3 mb-md-0">
                  <span className="f-w-700 f-14">Block</span>
                  <Select
                    className   = "mt-2"
                    placeholder = "Pilih Block"
                    isMulti     = {false}
                    options     = {optionBlock}
                    value       = {selectedBlock}
                    isDisabled  = {loadingScores}
                    onChange    = {(option) => {
                      setSelectedBlock(option)
                      selectedBlock && setSelectGroup(null)
                    }}
                  />
                </div>
              )}
              {selectSemester && (
                <div className="col-md-4 mb-3 mb-md-0">
                  <span className="f-w-700 f-14">Kelas</span>
                  <Select
                    className   = "mt-2"
                    placeholder = "Pilih kelas..."
                    isMulti     = {false}
                    options     = {groups}
                    value       = {selectGroup}
                    isClearable = {true}
                    isLoading   = {loadingScores}
                    isDisabled  = {loadingScores}
                    onChange    = {(option) => {
                      setSelectGroup(option)
                    }}
                  />
                </div>
              )}
            </div>
            <div className="dt-responsive table-responsive">
              <DataTableWithFilter
                className={'table table-striped table-bordered nowrap'}
                data={data}
                totalRows={totalRecords}
                show={[10, 20, 30, 40, 50]}
                onChangeSearch={(val) => {
                  setSearch(val)
                }}
                onChangeLimit={(val) => {
                  setLimit(val)
                }}
                onChangePage={(val) => {
                  setOffset(val)
                }}
                column={[
                  'Nama',
                  'No. Induk',
                  'Username',
                  'Status',
                  'Catatan',
                  'Actions',
                ]}
                tableHeader={
                  selectGroup?.value && selectSemester?.value && selectedBlock?.value
                  ?
                  <TableHeaderZip 
                    kelas={selectGroup}
                    semester={selectSemester}
                    period={selectedBlock}
                  />
                  : null
                }
                renderItem={(item, i) => (
                  <Item
                    item={item}
                    selectSemester={selectSemester}
                    selectedBlock={selectedBlock}
                    selectedGroup={selectGroup}
                    setHistoryRapor={setHistoryRapor}
                    key={i}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </Container>

      <RaporBlockAllModal historyRapor={historyRapor}/>
    </TemplateAdmin>
  )  
}
