import BaseButton from "component/button/BaseButton"
import { useDownloadContentZip } from "hooks/useDownloadContentZip"

/**
 * Table Header Zip
 * 
 * @param {{
 *  type?: 'rapor-block' | 'assignment'
 *  period: {
 *    value: string
 *    label: string
 *  }
 *  semester: {
 *    value: string
 *    label: string
 *  }
 *  kelas: {
 *    value: string
 *    label: string
 *  }
 *  subMapelId: string
 *  content: object
 *  isDisabledButton?: boolean
 * }} props
*/
export default function TableHeaderZip({ type = 'rapor-block', isDisabledButton, ...props}) {
  const { data, handler } = useDownloadContentZip()

  const downloadZipByTypeHandler = () => {
    switch (type) {
      case 'rapor-block':
        return handler.downloadHandler(props)
      case 'assignment':
        return handler.downloadAssignmentHandler(props)
      default:
        return handler.downloadHandler(props)
    }
  }

  const loadingZipByTypeHandler = () => {
    switch (type) {
      case 'rapor-block':
        return data.isLoadingDownload
      case 'assignment':
        return data.isLoadingAssignmentDownload
      default:
        return data.isLoadingDownload
    }
  }

  return (
    <div>
      <BaseButton props={{ className: 'mb-3' }} onClick={downloadZipByTypeHandler} isLoading={loadingZipByTypeHandler()} isDisabled={isDisabledButton | false}
      variant="warning"
      >
        <i className="fas fa-file-archive mr-1" />
        Zip
      </BaseButton>
    </div>
  )
}