import { useBaseContext } from "context/base"
import { useState } from "react"
import { createQueryUrl } from "util/string"
import useFile from "hooks/useFile"

export function useDownloadContentExcel() {
  const { getRequest } = useBaseContext()
  const { downloadFile } = useFile()

  const [isLoadingDownloadExcel, setIsLoadingDownloadExcel] = useState(false)

  /**
   * Download Excel Handler
   * 
   * @param {{
   *  group: {
   *    value: string
   *    label: string
   *  }
   *  content: {
   *    id: string
   *    title: string
   *  }
   *  kelasId: string
   * }} params
   */
  const downloadExcelHandler = async ({ 
    group,
    content, 
    kelasId
  }) => {
    setIsLoadingDownloadExcel(true)
    const queryGroupId = createQueryUrl({ queryName: 'group_id', value: group?.value == 'all' ? null : group?.value })
    const response = await getRequest(`export-excel-content?class_id=${kelasId}&content_id=${content?.id}${queryGroupId}`, true, { responseType: 'blob' })

    if (response) {
      setIsLoadingDownloadExcel(false)
      downloadFile({ blobData: response, fileName: `LMS - ${group?.value == 'all' ? '' : group?.label + '_'}${content?.title}.xlsx` })
    } else {
      setIsLoadingDownloadExcel(false)
    }
  }

  return {
    data: {
      isLoadingDownloadExcel,
    },
    set: {
      setIsLoadingDownloadExcel,
    },
    handler: {
      downloadExcelHandler
    },
  }
}