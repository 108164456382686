import BaseButton from 'component/button/BaseButton'
import { generate } from 'generate-password'
import React, { useEffect } from 'react'

/**
 * 
 * @param {{
 *  id: string
 *  value: any
 *  refFormkode: import('react').MutableRefObject<undefined> 
 *  isInvalid: boolean
 *  isDisabled?: boolean
 *  placeholder?: string
 *  maxLength?: number
 *  onChange: (event: React.ChangeEventHandler<HTMLInputElement>) => void
 * }} props
 * @returns 
 */
export default function InputFormKode({ 
  id,
  value, 
  refFormkode, 
  isInvalid,
  isDisabled = true,
  placeholder = "5 karakter kombinasi huruf/angka",
  maxLength = 5,
  onChange,
}) {
  const onClickGenerateCodeHandler = () => {
    onChange({
      target: {
        value: generate({
          length: maxLength,
          uppercase: true,
          numbers: true,
        })
      }
    })
  }

  useEffect(() => {
    onClickGenerateCodeHandler()
  }, [])

  return (
    <div className='d-flex align-items-center'>
      {/* {isDisabled
      ? null
      :
        <div>
          <BaseButton
            variant='transparent'
            props={{
              title: 'Kode acak',
              className: 'btn-outline-primary btn-sm m-0'
            }}
            onClick={onClickGenerateCodeHandler}
          >
            <i className="feather icon-shuffle" />
          </BaseButton>
        </div>
      } */}
      <div className='w-100'>
        <input
          value={value}
          maxLength={maxLength}
          onChange={onChange}
          ref={refFormkode}
          type="text"
          className={`${
            isInvalid ? 'is-invalid' : ''
          } form-control`}
          id={id}
          placeholder={placeholder}
          disabled={isDisabled}
        />
      </div>
    </div>
  )
}