import { useBaseContext } from "context/base"
import { useState } from "react"
import { createQueryUrl } from "util/string"
import useFile from "hooks/useFile"

export function useDownloadContentZip() {
  const { getRequest } = useBaseContext()
  const { downloadFile } = useFile()
  const profile = localStorage.getItem('profile')
  
  const [isLoadingDownload, setIsLoadingDownload] = useState(false)
  const [isLoadingAssignmentDownload, setIsLoadingAssignmentDownload] = useState(false)

  /**
   * Download Zip Handler
   * 
   * @param {{
   *  period: {
   *    value: string
   *    label: string
   *  }
   *  semester: {
   *    value: string
   *    label: string
   *  }
   *  kelas: {
   *    value: string
   *    label: string
   *  }
   * }} params
   */
  const downloadHandler = async ({ 
    kelas,
    period,
    semester,
  }) => {
    setIsLoadingDownload(true)
    const response = await getRequest(`download-rapor-period?period_id=${period.value}&semester=${semester.value}&group_id=${kelas.value}`, true, { responseType: 'blob' })

    if (response) {
      setIsLoadingDownload(false)
      downloadFile({ blobData: response, fileName: `LMS_Rapor Block_${JSON.parse(profile)?.name}-${semester?.label}-${period?.label}-${kelas?.label}.zip` })
    } else {
      setIsLoadingDownload(false)
    }
  }

  /**
   * Download Assignment Zip Handler
   * 
   * @param {{
   *  subMapelId: string
   *  content: object
   *  kelas: {
   *    value: string
   *    label: string
   *  }
   * }} params
   */
  const downloadAssignmentHandler = async ({ 
    subMapelId,
    content,
    kelas,
  }) => {
    setIsLoadingAssignmentDownload(true)
    const queryGroupId = createQueryUrl({ queryName: 'group_id', value: kelas?.value == 'all' ? null : kelas?.value })

    const response = await getRequest(`download-assignment-file?class_id=${subMapelId}&content_id=${content?.id}${queryGroupId}`, true, { responseType: 'blob' })

    if (response) {
      setIsLoadingAssignmentDownload(false)
      downloadFile({ blobData: response, fileName: `LMS - ${kelas?.value == 'all' ? '' : kelas?.label + '_'}${content?.title}.zip` })
    } else {
      setIsLoadingAssignmentDownload(false)
    }
  }

  return {
    data: {
      isLoadingDownload,
      isLoadingAssignmentDownload,
    },
    set: {
      setIsLoadingDownload,
    },
    handler: {
      downloadHandler,
      downloadAssignmentHandler,
    },
  }
}