import React from 'react'
import LoadingSpinner from 'component/loading'
import NotFound from 'component/not-found'
import ModalRaporTable from './modal-rapor-block-table'
import ModalRaporTableSkeleton from './modal-rapor-block-table-skeleton'

export default function ModalRaporDetail({
  loadingTypes,
  loadingStatus,
  detailRapor,
  context,
}) {
  const tableConfig = {
    headers: ['No', 'Semester', 'Semester Period', 'Download'],
    totalColumns: 3,
  }
  return (
    <div
      id="modalRaporBlockDetail"
      className="modal fade"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalRaporDetailTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div
            className="modal-header"
            style={{ padding: '10px' }}
          >
            {loadingStatus === loadingTypes.start && (
              <div>
                <h5
                  className="modal-title"
                  id="modalRaporDetailTitle"
                >
                  Memuat...
                </h5>
                <p className="mb-0 text-secondary">No. Induk: -</p>
                <p className="mb-0 text-secondary">Tahun Masuk: -</p>
              </div>
            )}
            {loadingStatus === loadingTypes.success && (
              <div>
                <h5
                  className="modal-title"
                  id="modalRaporDetailTitle"
                >
                  {detailRapor?.user?.name}
                </h5>
                <p className="mb-0 text-secondary">
                  No. Induk: {detailRapor?.user?.nim}
                </p>
                <p className="mb-0 text-secondary">
                  Tahun Masuk: {detailRapor?.angkatan?.tahun_mulai}
                </p>
              </div>
            )}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div
            className="modal-body"
            style={{ padding: '10px' }}
          >
            {loadingStatus === loadingTypes.start && (
              <ModalRaporTableSkeleton
                tableConfig={tableConfig}
                bodyPlaceholder={<LoadingSpinner />}
              />
            )}
            {loadingStatus === loadingTypes.success && (
              <ModalRaporTable
                tableConfig={tableConfig}
                detailRapor={detailRapor}
              />
            )}
            {loadingStatus === loadingTypes.error && (
              <ModalRaporTableSkeleton
                tableConfig={tableConfig}
                bodyPlaceholder={
                  <NotFound text="Terjadi kesalahan, coba beberapa saat lagi" />
                }
              />
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn  btn-secondary"
              data-dismiss="modal"
            >
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
