import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useBaseContext } from 'context/base'
import ContentType from 'component/content/content-type'
import ContentDuration from 'component/content/content-type/content-duration'
import useOverviewContentContext from './context/overviewContent.context'

const Content = ({ item, classId, sections, access, index }) => {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { deleteRequest } = useBaseContext()
  const overviewContentContext = useOverviewContentContext()

  /* ======================================= Local States ======================================= */
  const [isHovered, setIsHovered] = useState(false)

  function handleDashboardContent() {
    history.push(`/kelas/${classId}/content/${item.id}`, { content: item })
  }

  function handleEditContent() {
    switch (item.content_type_id) {
      case 1:
        history.push(`/kelas/${classId}/edit-content-text/${item.id}`, {
          kelasId: classId,
          dataContent: item,
          sectionList: sections,
        })
        break
      case 2:
        history.push(`/kelas/${classId}/edit-content-audio/${item.id}`, {
          kelasId: classId,
          dataContent: item,
          sectionList: sections,
        })
        break
      case 3:
        history.push(`/kelas/${classId}/edit-content-video/${item.id}`, {
          kelasId: classId,
          dataContent: item,
          sectionList: sections,
        })
        break
      case 4:
        history.push(`/kelas/${classId}/edit-content-document/${item.id}`, {
          kelasId: classId,
          dataContent: item,
          sectionList: sections,
        })
        break
      case 5:
        history.push(`/kelas/${classId}/edit-content-iframe/${item.id}`, {
          kelasId: classId,
          dataContent: item,
          sectionList: sections,
        })
        break
      case 6:
        history.push(`/kelas/${classId}/edit-content-quiz/${item.id}`, {
          kelasId: classId,
          existingData: item,
          sectionList: sections,
        })
        break
      case 7:
        history.push(`/kelas/${classId}/edit-content-survey/${item.id}`, {
          kelasId: classId,
          existingData: item,
          sectionList: sections,
        })
        break
      case 8:
        history.push(`/kelas/${classId}/edit-content-assignment/${item.id}`, {
          kelasId: classId,
          existingData: item,
          sectionList: sections,
        })
        break
      case 9:
        history.push(`/kelas/${classId}/edit-content-graphor/${item.id}`, {
          kelasId: classId,
          existingData: item,
          sectionList: sections,
        })
        break
      case 10:
        history.push(`/kelas/${classId}/edit-content-whiteboard/${item.id}`, {
          kelasId: classId,
          dataContent: item,
          sectionList: sections,
        })
        break
      default:
        break
    }
  }

  function handleDeleteContent() {
    Swal.fire({
      title: 'Apa anda yakin?',
      text: `Anda akan menghapus ${item.title}!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`contents?id=${item.id}`)
        if (response) {
          Swal.fire('Berhasil', 'Materi berhasil dihapus.', 'success').then(
            () => history.go(0),
          )
        } else {
          Swal.fire(
            'Oppsss!',
            'Terjadi kesalahan coba beberapa saat lagi.',
            'error',
          )
        }
      }
    })
  }

  /* ========================================== Output ========================================== */
  return (
    <li>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="p-3 f-16 text-left text-dark"
        style={{
          width: '100%',
          height: '75px',
          backgroundColor: isHovered
            ? 'rgba(221, 221, 221, 0.2)'
            : 'transparent',
        }}
      >
        <div className="row">
          <div className="col d-flex justify-content-between align-items-center">
            <span
              style={{
                display: 'inline-block',
              }}
            >
              <span className={`text-dark d-block f-16`}>
                {item.urutan}
                {'. '}
                {item.title}{' '}
                {item.active ? (
                  <span
                    className="badge badge-success ml-2"
                    style={{ fontSize: '12px' }}
                  >
                    Aktif
                  </span>
                ) : (
                  <span
                    className="badge badge-secondary ml-2"
                    style={{ fontSize: '12px' }}
                  >
                    Tidak Aktif
                  </span>
                )}
                {item?.is_spesifik === true
                ?
                <span
                  className="badge badge-primary ml-2"
                  style={{ fontSize: '12px' }}
                >
                  Spesifik Siswa {item?.content_specific_users?.length ? `(${item?.content_specific_users.length})` : ''}
                </span>
                : null}
                {item?.is_score
                ?
                <span
                  className="badge badge-warning ml-2"
                  style={{ fontSize: '12px' }}
                >
                  Penilaian
                </span>
                : null}
              </span>
              <span className="d-block">
                <ContentType id={item.content_type_id} />
                <ContentDuration durasi={item.durasi} />
              </span>
            </span>
            <span
              style={{
                display: isHovered ? 'inline-block' : 'none',
              }}
            >
              <button
                type="button"
                className='btn btn-sm btn-primary mr-2'
                onClick={() => overviewContentContext.handler.toggleShowModalSelectedStudentHandler(true, {
                  ...item,
                  renderTitle: <>
                  <ContentType id={item.content_type_id} />
                  <div style={{ textDecoration: 'underline' }}>
                    {item.urutan}. {item?.title}
                  </div>
                  </>
                })}
              >
                <i className="feather icon-user mr-1" />
                <span>Siswa</span>
              </button>

              <button
                type="button"
                disabled={access}
                className={`btn btn-sm btn-success mr-2 ${
                  access ? 'btn-forbidden ' : ''
                }`}
                onClick={handleEditContent}
              >
                <i className="feather icon-edit mr-1" />
                <span>Edit</span>
              </button>
              <button
                type="button"
                className="btn btn-sm btn-warning mr-2"
                onClick={handleDashboardContent}
              >
                <i className="feather icon-check-square mr-1" />
                <span>Overview</span>
              </button>
              <button
                type="button"
                disabled={access}
                className={`btn btn-sm btn-danger ${
                  access ? 'btn-forbidden ' : ''
                }`}
                onClick={handleDeleteContent}
              >
                <i className="feather icon-trash-2 mr-1" />
                <span>Hapus</span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </li>
  )
}

export default Content
