/**
 * Overview Grade List Item Props
 * 
 * @param {{
 *  item: GradeItem
 *  access: boolean
 *  onClickGradeEditHandler: (gradeItem: GradeItem) => void
 *  onClickGradeRemoveHandler: (gradeItem: GradeItem) => void
 *  onClickGradeListContentHandler: (gradeItem: GradeItem) => void
 *  onClickGradeStudentHandler: (condition, data) => void
 * }} props
 */
export default function OverviewGradeListItem({
  item,
  access,
  onClickGradeEditHandler,
  onClickGradeRemoveHandler,
  onClickGradeListContentHandler,
  onClickGradeStudentHandler,
}) {
  return (
    <tr>
      <td style={{ width: 255 }}>{item.name}</td>
      <td>{item.total_siswa}</td>
      <td>{item.total_content}</td>
      <td>
        <div className="d-flex align-items-center" style={{ columnGap: 5 }}>
          <div>
            <button
              type="button"
              className={`btn btn-sm btn-primary mr-2 ${
                access ? 'btn-forbidden ' : ''
              }`}
              onClick={() => onClickGradeStudentHandler({
                ...item,
                renderTitle: <>
                  <h3>{item.name}</h3>
                </>
              })}
            >
              <i className="feather icon-user mr-1" />
              <span>Siswa</span>
            </button>
          </div>
          <div>
            <button
              type="button"
              disabled={access}
              className={`btn btn-sm btn-success mr-2 ${
                access ? 'btn-forbidden ' : ''
              }`}
              onClick={() => onClickGradeListContentHandler(item)}
            >
              <i className="feather icon-book mr-1" />
              <span>Daftar Materi</span>
            </button>
          </div>
          <div>
            <button
              type="button"
              disabled={access}
              className={`btn btn-sm btn-warning mr-2 ${
                access ? 'btn-forbidden ' : ''
              }`}
              onClick={() => onClickGradeEditHandler(item)}
            >
              <i className="feather icon-edit mr-1" />
              <span>Edit</span>
            </button>
          </div>
          <div>
            <button
              type="button"
              disabled={access}
              className={`btn btn-sm btn-danger mr-2 ${
                access ? 'btn-forbidden ' : ''
              }`}
              onClick={() => onClickGradeRemoveHandler(item)}
            >
              <i className="feather icon-trash mr-1" />
              <span>Hapus</span>
            </button>
          </div>
        </div>
      </td>
    </tr>
  )
}