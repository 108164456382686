import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { PDFReader } from 'reactjs-pdf-reader'
import moment from 'moment'
import YouTube from 'react-youtube'
import Select from 'react-select'
import Swal from 'sweetalert2'

import { useBaseContext } from 'context/base'
import useContentPlayerContext from 'context/content-player'
import { RatingFull, RatingStars } from 'component/rating'
import { avatarDefaultUser } from 'util/constant'
import CardMeta from 'component/card-meta'
import Modal from 'component/modal'
import AssignmentUsersColPrasyarat from './assignment-users-col-prasyarat'
import FormPenilaian from './form-penilaian'
import FormPenilaianEdit from './form-penilaian-edit/'
import FormPenilaianDisabled from './form-penilaian-disabled'
import { detectionDoc } from 'util/global'

import CkeditorReadonly from 'component/ckeditor-readonly'
import CkeditorReadonlyV2 from 'component/ckeditor-readonly/indexV2'
import BaseReactBootstrapModal from 'component/modal/BaseReactBootstrapModal'
import RejectModalWithRBModalComponent from 'component/modal/RejectModalWithRBModalComponent'
import DataTableWithFilter from 'component/data-table/indexWithFilter'
import TableHeaderExcel from 'component/table/TableHeaderExcel'
import { ASSIGNMENT_TYPE } from 'util/enum'
import TableHeaderZip from 'component/table/TableHeaderZip'

export const TextAnswer = ({ answer }) => {
  return <CkeditorReadonlyV2 text={answer.text}/>
}

export const AudioAnswer = ({ answer }) => {
  const data = answer.url?.data || answer.url

  return (
    <audio
      style={{ width: '100%' }}
      controls
      controlsList="nodownload"
      onContextMenu={(event) => event.preventDefault()}
    >
      <source
        src={data}
        type="audio/mpeg"
      />
      Your browser does not support the audio tag.
    </audio>
  )
}

export const VideoAnswer = ({ answer }) => {
  const data = answer.url?.data || answer.url

  return (
    <video
      width="100%"
      style={{ maxHeight: '75vh' }}
      controls
      disablePictureInPicture
      controlsList="nodownload"
      onContextMenu={(event) => event.preventDefault()}
    >
      <source
        src={data}
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>
  )
}

export const DocumentAnswer = ({ answer, assignmentTitle, siswaName }) => {
  const { getRequest } = useBaseContext()
  const pdfContainerRef = useRef()

  async function download() {
    const response = await getRequest(`checkfile?url=${answer.url}`)
    if (response) {
      window.location.href = answer.url
    }
  }
  const encode = encodeURIComponent(answer.url)

  const PdfPreviewer = () => {
    const [width, setWidth] = useState(null)
    const randomizedKey = Math.random()
    const renderKey = `${randomizedKey}_${width}`

    setTimeout(() => {
      setWidth(pdfContainerRef.current?.clientWidth || 650)
    }, 500)

    return width ? (
      <PDFReader
        key={renderKey}
        showAllPage={true}
        width={width}
        url={answer.url}
      />
    ) : (
      <span
        className="spinner-border spinner-border-lg text-info mt-2 mb-4"
        role="status"
      />
    )
  }

  return (
    <div
      className="row"
      ref={pdfContainerRef}
    >
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ flexDirection: 'column', width: '100%' }}
      >
        {answer.url_base64.type === 'pdf' ? (
          <PdfPreviewer />
        ) : (
          <iframe
            key={answer.id}
            title={answer.id}
            src={detectionDoc(answer.url_base64.type, encode)}
            style={{
              border: 'none',
              height: '100vh',
              width: '100%',
              backgroundColor: '#ddd',
            }}
          />
        )}
        <button
          onClick={download}
          type="button"
          className="btn btn-outline-info mt-2"
        >
          <i className="fas fa-download mr-2" />
          <span>Download Tugas</span>
        </button>
      </div>
    </div>
  )
}

export const YoutubeAnswer = ({ answer }) => {
  const ytId = answer.url?.data || answer.url

  return (
    <YouTube
      videoId={ytId}
      opts={{
        width: '100%',
        height: '450px',
      }}
    />
  )
}

export const UrlAnswer = ({ answer }) => {
  return (
    <div>
      <a
        href={answer?.url?.data || answer?.url}
        target="_blank"
      >
        {answer?.url?.data || answer?.url}
      </a>
      <br />
      <br />
      {answer?.url?.data || answer?.url ? (
        <CardMeta url={answer?.url?.data || answer?.url} />
      ) : null}
    </div>
  )
}

const AssignmentUsers = () => {
  /* ========================================== Helpers ========================================= */
  const params = useParams()
  const kelasId = params.kelasId

  /* =========================================== Refs =========================================== */
  const isMounted = useRef(true)

  /* ====================================== Consume Context ===================================== */
  const { getRequest, putRequest } = useBaseContext()
  const { content } = useContentPlayerContext()

  const initSelectedGroup = () => {
    return content?.selectedStudent?.selectedGroup || { value: null, label: 'Pilih Kelas' }
  }

  /* ======================================= Local States ======================================= */
  const [assignmentUsers, setAssignmentUsers]     = useState([])
  const [grupKecilData, setGrupKecilData]         = useState(null)
  const [loading, setLoading]                     = useState(false)
  const [totalRows, setTotalRows]                 = useState(0)
  const [limit, setLimit]                         = useState(10)
  const [offset, setOffset]                       = useState(0)
  const [keyword, setKeyword]                     = useState('')
  const [detail, setDetail]                       = useState(null)
  const [groups, setGroups]                       = useState([])
  const [group, setGroup]                         = useState(initSelectedGroup)
  const [discuss, setDiscuss]                     = useState([])
  const [validDiscuss, setValidDiscuss]           = useState(0)
  const [feedbackCompleted, setFeedbackCompleted] = useState([])
  const [historyAssigment, setHistoryAssigment] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)
  const [isShowModalDetailTugas, setIsShowModalDetailTugas] = useState(false)
  const [isShowModalReject, setIsShowModalReject] = useState(false)
  const [grade, setGrade] = useState(null)
  const [assignment, setAssignment] = useState(null)

  /* ========================================= Functions ======================================== */
  const fetchGrupKecilHandler = async () => {
    const response = await getRequest(`forum-group-teacher?content_id=${content.id}`)
    setGrupKecilData(response.data)

    return response
  }

  async function getAssignmentAll() {
    const group_id = group?.value === 'all' ? null : group?.value
    if (group?.value !== null) {
      const response = await getRequest(
        `assigmentusers-bycontent?id=${content.id}&offset=${offset}&limit=${limit}&keyword=${keyword}&group_id=${group_id}`,
      )
      const fetchGrupKecil = await fetchGrupKecilHandler()
  
      setLoading(false)
      if (response && fetchGrupKecil) {
        setAssignmentUsers(response.data)
        setTotalRows(response.totalRows)
      }
    }
  }

  const getAssignmentAndShowDetail = useCallback(async () => {
    if (content?.urlQueryFetch) {
      const response = await getRequest(
        `assigmentusers-bycontent${content.urlQueryFetch}`,
      )
      const responseFetchGrupKecil = await fetchGrupKecilHandler()
  
      if (Array.isArray(response?.data) && response?.data?.length && responseFetchGrupKecil) {
        const currentAssigment = response?.data?.[0]?.assigment_users.find((i) => i.d_login == null)
        const data = {
          ...response?.data?.[0],
          grupKecilData: responseFetchGrupKecil?.data,
          assigment : currentAssigment
        }
        handleDetail(data)
        setIsShowModalDetailTugas(true)
      }
    }
  }, [content?.urlQueryFetch]);

  async function getGroups() {
    const response = await getRequest(
      `group-by-mapel?class_id=${kelasId}`,
    )
    if (response) {
      if (response.data.length !== 0) {
        const groups = response.data.map((group) => ({
          value: group.id,
          label: group.group,
        }))
        setGroups([{ value: 'all', label: 'Semua Kelas' }, ...groups])
      }
    }
  }

  async function handleDetail(item) {
    const response = await getDiscuss(item.assigment.id)

    const participantsId =
      [
        ...new Set(
          (grupKecilData || item?.grupKecilData)?.reduce((arr, next) => {
            next.forums &&
              next.forums.forEach((_next) => {
                _next.forums &&
                  _next.forums.forEach((__next) => {
                    arr.push(__next.login.user_id)
                  })
                arr.push(_next.login.user_id)
              })
            return arr
          }, []),
        ),
      ] || []

    const isParticipated = participantsId.some((uid) => uid === item.id)
    if (response) {
      const feedback = content.feed?.feed_temps.filter(
        (item) =>
          item.content_id == response.content_id &&
          item.user_id == response.user_id,
      )
      setFeedbackCompleted(feedback)
      const _isGrupKecilRequired = content.group_status
      const _isGrupKecilPassed = !_isGrupKecilRequired || isParticipated

      setDetail({
        ...response,
        _isGrupKecilPassed,
      })
    }
  }

  async function getDiscuss(id) {
    const response = await getRequest(
      `assigmentusers-discuss?id=${id}&content_id=${content.id}`,
    )
    if (response) {
      setDiscuss(response.data)
      setValidDiscuss(response.validFeedback)
      setGrade(response?.content_grade)
      return response.assignmentUser
    } else {
      return null
    }
  }

  function handleCloseModal() {
    setDetail(null)
    setValidDiscuss(0)
    setIsShowModalDetailTugas(false)
  }

  async function getAssignment() {
    setAssignment(null)
    const response = await getRequest(
      `assignment-siswa?id=${content.contents_url}`,
    )
    if (response) {
      setAssignment(response.data)
    }
  }

  async function handleDeleteAssignmentUsers(item) {
    try {
      let form = new FormData();

      if (item?.inputValue) {
        form.append('note', item.inputValue);
      }

      const response = await putRequest(
        `assignment-siswa/${item.assigment.id}/reject`,
        form,
      );

      if (response) {
        Swal.fire({
          icon: 'success',
          title: `Tugas ${item.name} berhasil ditolak`,
        }).then(async () => await getAssignmentAll())
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: `Tugas ${item.name} gagal ditolak`,
      });
    }
  }

  useEffect(() => {
    getAssignmentAndShowDetail()
    getAssignment()
  }, [])

  /* ======================================== Components ======================================== */

  const AssignmentUserItem = ({
    item,
    setDetail,
    setSelectedItem,
    setHistoryAssigment,
  }) => {
    var currentAssigment = item.assigment_users.find((i) => i.d_login == null)
    var rejectAssigment = item.assigment_users.filter((i) => i.d_login)
    item.assigment = currentAssigment


    return (
      <tr>
        <td>
          <div className="d-inline-block align-middle">
            <img
              src={`${item.linkAvatar}`}
              alt={item.name}
              className="img-radius align-top m-r-15"
              style={{ width: '40px', height: '40px' }}
              onError={(event) => {
                event.currentTarget.src = avatarDefaultUser
              }}
            />
            <div className="d-inline-block">
              <h6 className="m-b-0 mt-2">{item.name}</h6>
            </div>
          </div>
        </td>
        <td>{item.nim}</td>
        <td>
          {currentAssigment ? (
            currentAssigment?.score !== null ? (
              <>
                <strong>{currentAssigment?.score}</strong>
                <br />
                {currentAssigment.url == null &&
                  currentAssigment.text == null && (
                    <span className="badge badge-info">
                      Tuntas melalui pathway content oleh:{' '}
                      {currentAssigment?.completed_by_teachers}
                    </span>
                  )}
              </>
            ) : (
              <span className="badge badge-secondary">Belum diperiksa</span>
            )
          ) : (
            <span className="badge badge-danger">Tugas ditolak</span>
          )}
        </td>
        <td>{currentAssigment?.createdAt ? `${moment(currentAssigment?.createdAt).format('DD MMM YYYY, HH:mm')} WIB` : '-'}</td>
        <td>
          {currentAssigment ? (
            (currentAssigment.url || currentAssigment.text) && (
              <>
                <button
                  className="btn btn-sm btn-primary mr-2"
                  // data-toggle="modal"
                  // data-target="#tugasKomentar"
                  onClick={() => {
                    setDetail(item)
                    setIsShowModalDetailTugas(true)
                  }}
                >
                  <i className="fas fa-eye mr-1" />
                  Detail
                </button>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => {
                    setSelectedItem(item)
                    setIsShowModalReject(true)
                  }}
                >
                  <i className="feather icon-x-circle mr-1" />
                  Tolak Tugas
                </button>
              </>
            )
          ) : (
            <button
              className="btn btn-sm btn-warning mr-2"
              data-toggle="modal"
              data-target="#modalHistoryAssignment"
              onClick={() => setHistoryAssigment(rejectAssigment)}
            >
              <i className="fas fa-eye mr-1" />
              History
            </button>
          )}
        </td>
        <AssignmentUsersColPrasyarat item={item} />
      </tr>
    )
  }

  const AssignmentUserGroupPicker = () => (
    <>
      <h5>Pilih Kelas: </h5>
      <Select
        placeholder="Kelas"
        options={groups}
        onChange={(item) => setGroup(item)}
        className="mb-3"
        defaultValue={group || { value: null, label: 'Pilih Kelas' }}
      />
    </>
  )

  /* ========================================== Effects ========================================= */
  // useEffect(() => {
  //   if (group && groups?.length < 1 && isMounted.current) {
  //   }

  //   return () => { isMounted.current = false; }
  // }, [groups]);

  useEffect(() => {
    setOffset(0)
  }, [keyword, limit, group])

  useEffect(() => {
    if (isMounted.current && group) {
      setLoading(true)
      getAssignmentAll()
    }
  }, [offset, keyword, limit, group])

  useEffect(() => {
    getGroups()
  }, [])

  /* ======================================== Constants ======================================= */
  const datatableColumn = ['Nama', 'No. Induk', 'Nilai', 'Tanggal Pengumpulan', 'Actions']
  if (
    content.feedback ||
    content.group_status ||
    (content.komentar_tugas && content.komentar_wajib > 0)
  )
    datatableColumn.push('Prasyarat')
  return (
    <div className="dt-responsive table-responsive">
      {/* <AssignmentUserGroupPicker /> */}
      {content.komentar_tugas ? (
        <>
          {content.komentar_wajib ? (
            <div
              className="mt-2 border rounded alert alert-warning w-100"
              role="alert"
            >
              <strong>
                <i className="fas fa-info-circle mr-1" />
                Siswa wajib memberikan {content.komentar_wajib} komentar
                terhadap tugas siswa lain.
              </strong>
            </div>
          ) : null}
        </>
      ) : null}
      {(content.feedback || content.group_status) && (
        <div
          className="mt-2 border rounded alert alert-warning w-100"
          role="alert"
        >
          <strong>
            <i className="fas fa-info-circle mr-1" />
            Siswa wajib
            {content.feedback && ` memberikan feedback`}
            {content.feedback && content.group_status && ` dan`}
            {content.group_status && ` terlibat dalam diskusi kecil`} pada
            materi.
          </strong>
        </div>
      )}
      <DataTableWithFilter
        className={'table table-striped table-bordered nowrap'}
        data={assignmentUsers}
        totalRows={totalRows}
        show={[10, 20, 30]}
        onChangeSearch={(val) => setKeyword(val)}
        onChangeLimit={(val) => setLimit(val)}
        onChangePage={(val) => setOffset(val)}
        column={datatableColumn}
        tableHeader={
          <div className='d-flex align-items-center' style={{
            gap: 10
          }}>
            {assignmentUsers?.length ? <TableHeaderExcel group={group} kelasId={kelasId} content={content} /> : null}
            {
              [
                ASSIGNMENT_TYPE.AUDIO,
                ASSIGNMENT_TYPE.VIDEO,
                ASSIGNMENT_TYPE.DOCUMENT,
              ].includes(assignment?.reply_type)
              && assignmentUsers?.length
              ?
              <TableHeaderZip
                subMapelId={kelasId}
                kelas={group}
                content={content}
                type='assignment'
              />
              : null
            }
          </div>
        }
        filter1={{
          placeholder: "Kelas",
          options: groups,
          onChange: setGroup,
          className: "mb-3",
          defaultValue: group || { value: null, label: 'Pilih Kelas' },
        }}
        renderItem={(val, index) => (
          <AssignmentUserItem
            item={val}
            key={index}
            setDetail={handleDetail}
            setHistoryAssigment={setHistoryAssigment}
            setSelectedItem={setSelectedItem}
          />
        )}
      />

      <RejectModalWithRBModalComponent show={isShowModalReject} item={selectedItem} title='tugas' onReject={handleDeleteAssignmentUsers} isWithCloseButton={false} isWithXButton={false} isWithHeader={false} handleClose={() => setIsShowModalReject(false)} />
      <Modal
        id="modalHistoryAssignment"
        title="Riwayat tugas"
      >
        <div
          className="hd-body pr-2"
          style={{
            maxHeight: '350px',
            overflowY: 'auto',
          }}
        >
          {historyAssigment.map((history, index) => (
            <div
              key={index}
              className="excerpt rounded mb-3"
            >
              <div className="d-flex align-items-center justify-content-between">
                <span>
                  <i className="feather icon-calendar mr-1 f-14" />
                  {history.login.login_at
                    ? moment(history.login.login_at).format(
                      'HH:mm:ss, DD-MM-YYYY',
                    )
                    : '-'}
                </span>
                <span className="badge badge-danger">
                  Ditolak oleh {history.login.user.name}
                </span>
              </div>
              <h6 className="my-1">Catatan:</h6>
              {
                history?.note ?
                  <CkeditorReadonly data={history.note} />
                  : '-'
              }
            </div>
          ))}
        </div>
      </Modal>
      <BaseReactBootstrapModal show={isShowModalDetailTugas} enforceFocus={false} headerTitle={detail ? (
        <div className="d-flex align-items-center">
          <img
            src={`${detail.user.linkAvatar}`}
            alt={detail.user.name ? detail.user.name : 'User'}
            className="img-radius"
            style={{ height: '30px', width: '30px' }}
            onError={(e) => (e.currentTarget.src = avatarDefaultUser)}
          />
          <p className="m-0 ml-2 f-w-700 f-18 d-flex align-items-center">
            {detail.user.name}
          </p>
          {grade
            ?
              <span key={`list-grade-student${grade?.id}`} className="badge badge-primary ml-1" style={{ fontSize: '.8rem' }}>Grade {grade?.name}</span>
            : null}
        </div>
      ) : (
        <div className="d-flex">
          <img
            src={avatarDefaultUser}
            alt="User"
            className="img-radius mb-2"
            style={{ height: '30px', width: '30px' }}
          />
          <div className="ml-2"></div>
        </div>
      )} size='lg' isWithFooter={true} closeTitle='Tutup' handleClose={handleCloseModal} isWithOkButton={false}>
        {detail && (
          <>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    {/* IMPACT S3 */}
                    {detail.assigment.reply_type == 1 && (
                      <TextAnswer answer={detail} />
                    )}
                    {detail.assigment.reply_type == 2 && (
                      <AudioAnswer answer={detail} />
                    )}
                    {detail.assigment.reply_type == 3 && (
                      <VideoAnswer answer={detail} />
                    )}
                    {detail.assigment.reply_type == 4 && (
                      <DocumentAnswer
                        answer={detail}
                        assignmentTitle={detail.assigment.title}
                        siswaName={detail.user.name}
                      />
                    )}
                    {detail.assigment.reply_type == 5 && (
                      <YoutubeAnswer answer={detail} />
                    )}
                    {detail.assigment.reply_type == 6 && (
                      <UrlAnswer answer={detail} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {content.komentar_tugas ? (
                  <>
                    {content.komentar_wajib ? (
                      <>
                        <div
                          className="mt-2 border rounded alert alert-warning w-100"
                          role="alert"
                        >
                          <strong>
                            <i className="fas fa-info-circle mr-1" />
                            Siswa wajib memberikan{' '}
                            {content.komentar_wajib} feedback terhadap
                            tugas siswa lain.
                          </strong>
                        </div>
                        {validDiscuss >= content.komentar_wajib ? (
                          <div
                            className="mt-2 border rounded alert alert-success w-100"
                            role="alert"
                          >
                            <strong>
                              <i className="fas fa-check-circle mr-1" />
                              {detail.user.name} sudah memberikan{' '}
                              {validDiscuss} feedback terhadap tugas siswa
                              lain.
                            </strong>
                          </div>
                        ) : null}
                        {validDiscuss < content.komentar_wajib ? (
                          <>
                            {validDiscuss === 0 ? (
                              <div
                                className="mt-2 border rounded alert alert-danger w-100"
                                role="alert"
                              >
                                <strong>
                                  <i className="fas fa-info-circle mr-1" />
                                  {detail.user.name} belum memberikan
                                  feedback terhadap tugas siswa lain.
                                </strong>
                              </div>
                            ) : (
                              <div
                                className="mt-2 border rounded alert alert-info w-100"
                                role="alert"
                              >
                                <strong>
                                  <i className="fas fa-info-circle mr-1" />
                                  {detail.user.name} sudah memberikan{' '}
                                  {validDiscuss} feedback terhadap tugas
                                  siswa lain.
                                </strong>
                              </div>
                            )}
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </>
                ) : null}
              </div>
              <div className="col-12">
                {(content.feedback || content.group_status) && (
                  <>
                    <div
                      className="mt-2 border rounded alert alert-warning w-100"
                      role="alert"
                    >
                      <strong>
                        <i className="fas fa-info-circle mr-1" />
                        Siswa wajib
                        {content.feedback && ` memberikan feedback`}
                        {content.feedback &&
                          content.group_status &&
                          ` dan`}
                        {content.group_status &&
                          ` terlibat dalam diskusi kecil`}{' '}
                        pada materi.
                      </strong>
                    </div>
                    {feedbackCompleted?.length ? (
                      <div
                        className="mt-2 border rounded alert alert-success w-100"
                        role="alert"
                      >
                        <strong>
                          <i className="fas fa-check-circle mr-1" />
                          Siswa sudah memberikan feedback pada materi.
                        </strong>
                      </div>
                    ) : (
                      content.feedback && (
                        <div
                          className="mt-2 border rounded alert alert-danger w-100"
                          role="alert"
                        >
                          <strong>
                            <i className="fas fa-info-circle mr-1" />
                            Siswa belum memberikan feedback pada materi.
                          </strong>
                        </div>
                      )
                    )}
                    {detail._isGrupKecilPassed ? (
                      <div
                        className="mt-2 border rounded alert alert-success w-100"
                        role="alert"
                      >
                        <strong>
                          <i className="fas fa-check-circle mr-1" />
                          Siswa sudah terlibat dalam diskusi kecil pada
                          materi.
                        </strong>
                      </div>
                    ) : (
                      content.group_status && (
                        <div
                          className="mt-2 border rounded alert alert-danger w-100"
                          role="alert"
                        >
                          <strong>
                            <i className="fas fa-info-circle mr-1" />
                            Siswa belum terlibat dalam diskusi kecil pada
                            materi.
                          </strong>
                        </div>
                      )
                    )}
                  </>
                )}
              </div>
              <div className="col-12">
                {detail.score !== null ? (
                  <FormPenilaianEdit
                    assignmentUser={detail}
                    handleCloseModal={handleCloseModal}
                    getAssignmentUsers={getAssignmentAll}
                  />
                ) : (
                  <>
                    {content.komentar_tugas &&
                      content.komentar_wajib > 0 &&
                      content.feedback ? (
                      <>
                        {content.komentar_wajib && content.feedback ? (
                          <>
                            {detail._isGrupKecilPassed &&
                              validDiscuss >= content.komentar_wajib &&
                              feedbackCompleted.length > 0 ? (
                              <FormPenilaian
                                getAssignmentUsers={getAssignmentAll}
                                assignmentUserId={detail.id}
                                handleCloseModal={handleCloseModal}
                              />
                            ) : (
                              <FormPenilaianDisabled />
                            )}
                          </>
                        ) : (
                          <FormPenilaian
                            getAssignmentUsers={getAssignmentAll}
                            assignmentUserId={detail.id}
                            handleCloseModal={handleCloseModal}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {!detail._isGrupKecilPassed ||
                          (content.komentar_wajib &&
                            validDiscuss < content.komentar_wajib && content.komentar_tugas) ||
                          (content.feedback &&
                            feedbackCompleted.length === 0) ? (
                          <FormPenilaianDisabled />
                        ) : (
                          <FormPenilaian
                            getAssignmentUsers={getAssignmentAll}
                            assignmentUserId={detail.id}
                            handleCloseModal={handleCloseModal}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        )}
        <div className="row">
          <div className="col">
            <h5>Feedback ({discuss.length})</h5>
            {detail ? (
              <RatingFull
                rating={detail.rating}
                rating_1={detail.rating_1}
                rating_2={detail.rating_2}
                rating_3={detail.rating_3}
                rating_4={detail.rating_4}
                rating_5={detail.rating_5}
                totalRating={detail.count_rating}
                maxRating={5}
                starColor="#ffba57"
              />
            ) : null}
            {discuss.length !== 0 ? (
              <>
                {discuss.map((item, index) => {
                  // setLike(item.likes)
                  let dd = moment(item.createdAt)
                    .add(30, 'days')
                    .valueOf()
                  let date =
                    dd < moment().valueOf()
                      ? moment(item.createdAt).format('DD MMM YYYY')
                      : moment(item.createdAt).fromNow()
                  return (
                    <div
                      key={item.id}
                      className={`${index === 0 ? 'border-top mt-2' : ''
                        } border-bottom`}
                    >
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <div className="d-flex align-items-center">
                              <img
                                src={`${item.user.linkAvatar}`}
                                alt={
                                  item.user.name ? item.user.name : 'User'
                                }
                                className="img-radius mb-2"
                                style={{ height: '30px', width: '30px' }}
                                onError={(e) =>
                                (e.currentTarget.src =
                                  avatarDefaultUser)
                                }
                              />
                              <div className="ml-2">
                                <p className="mb-0 d-flex align-items-center">
                                  <span className="f-w-500">
                                    {item.user.name}
                                  </span>
                                </p>
                                <div>
                                  <span>
                                    <RatingStars
                                      rating={item.rating}
                                      starSize="10px"
                                      maxRating={5}
                                      starColor="#ffba57"
                                    />
                                  </span>
                                  <span
                                    className="text-muted ml-2 mb-2"
                                    style={{ fontSize: '10px' }}
                                  >
                                    <i className="feather icon-clock mr-1" />
                                    {date}
                                  </span>
                                </div>
                                {/* <p className="text-muted mb-2" style={{ fontSize: "10px" }} ><i className="feather icon-clock mr-1" />{date}</p> */}
                              </div>
                            </div>
                            <p>{item.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </>
            ) : (
              <div className="d-flex flex-column justify-content-center my-4">
                <i
                  className="fas fa-comments f-60 text-center mb-3"
                  style={{
                    color: '#ccc',
                  }}
                />
                <h6
                  className="text-center"
                  style={{
                    color: '#bbb',
                  }}
                >
                  Belum ada diskusi berlangsung
                </h6>
              </div>
            )}
          </div>
        </div>
      </BaseReactBootstrapModal>
    </div>
  )
}

export default AssignmentUsers
